<template>
  <section id="container" class="container">
    <section class="corder-price">
      <div class="home-price-item active" v-for="(item, index) in list" :key="index">
        <div v-if="item.popular" class="popular">{{item.popular}}</div>
        <div v-if="item.discount" class="discount">{{item.discount}}</div>
        <div v-else class="discount-p"></div>
        <div class="title">{{item.title}}</div>
        <div class="price">
          <span class="activity">${{item.activityPrice}}</span>
        </div>
        <div class="days">
          <span class="activity">{{item.days}}Days</span>
        </div>
        <div class="unit"><span class="tag">{{item.unitPrice}}</span></div>
      </div>
    </section>
    <el-descriptions class="corder-detail" :column="1">
      <div slot="title" class="title">
        <span>ORDERS: </span>
        <span class="code">{{order.code}}</span>
      </div>
      <el-descriptions-item label="Order type">{{order.type}}</el-descriptions-item>
      <el-descriptions-item label="Days count">{{order.days}}</el-descriptions-item>
      <el-descriptions-item label="Total price">${{order.activityPrice}}</el-descriptions-item>
    </el-descriptions>
    <div class="home-try-now">
      <div v-if="order.tip" class="corder-tip" v-html="order.tip"></div>
      <img v-if="order.tip" src="@/images/icon/usdt.png" width="300" height="300" ></img>
      <el-button v-else type="success" @click="handleContinue">Continue</el-button>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { AutoBodyHeight } from '@/mixins/body-height'
import { createOrder } from '@/apis/mine'

export default {
  mixins: [ AutoBodyHeight ],
  components: {},
  props: {},
  data () {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState({
      order: state => state.common.order
    })
  },
  watch: {},
  methods: {
    ...mapMutations(['setLoading', 'setCommonState']),
    init() {
      // console.log(this.order)
      this.list = [this.order]
    },
    handleContinue() {
      // console.log(this.order)
      this.setLoading({
        state: true,
        text: ''
      })
      createOrder({
        planType: this.order.type,
        productTitle: this.order.title
      }, (res) => {
        // console.log(res)
        var detail = this.order
        detail.code = res.code
        detail.tip = res.tip
        this.setCommonState({
          label: 'order',
          value: detail
        })
        this.$forceUpdate()
        // console.log(this.order)
        this.setLoading({
          state: false,
          text: ''
        })
      }, (err) => {
        // console.log(err)
        this.setLoading({
          state: false,
          text: ''
        })
      })
    }
  },
  created () {},
  mounted () {
    this.init()
  },
  beforeDestroy () {},
  destroyed () {}
}
</script>