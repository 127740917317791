export const AutoBodyHeight = {
  mounted () {
    // if (document.getElementById('container')) {
      var viewportHeight = window.innerHeight || document.documentElement.clientHeight;
      // console.log(viewportHeight)
      var header = document.getElementById('header')
      // console.log(header.offsetHeight)
      var footer = document.getElementById('footer')
      // console.log(footer.offsetHeight)
      var container = document.getElementById('container')
      // console.log(container.offsetHeight)
      if (viewportHeight - header.offsetHeight - footer.offsetHeight > container.offsetHeight) {
        var height = viewportHeight - header.offsetHeight - footer.offsetHeight
        // console.log(height)
        container.style.minHeight = height + 'px'
      }
    // }
  }
}